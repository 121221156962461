/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

@font-face {
    font-family: 'Noto Kufi Arabic';
    src: url('./fonts/NotoKufiArabic-Bold.woff2') format('woff2'),
        url('./fonts/NotoKufiArabic-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Kufi Arabic';
    src: url('./fonts/NotoKufiArabic-Black.woff2') format('woff2'),
        url('./fonts/NotoKufiArabic-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Kufi Arabic';
    src: url('./fonts/NotoKufiArabic-ExtraLight.woff2') format('woff2'),
        url('./fonts/NotoKufiArabic-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Kufi Arabic';
    src: url('./fonts/NotoKufiArabic-ExtraBold.woff2') format('woff2'),
        url('./fonts/NotoKufiArabic-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Kufi Arabic';
    src: url('./fonts/NotoKufiArabic-Light.woff2') format('woff2'),
        url('./fonts/NotoKufiArabic-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Kufi Arabic';
    src: url('./fonts/NotoKufiArabic-Medium.woff2') format('woff2'),
        url('./fonts/NotoKufiArabic-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Kufi Arabic';
    src: url('./fonts/NotoKufiArabic-Thin.woff2') format('woff2'),
        url('./fonts/NotoKufiArabic-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Kufi Arabic';
    src: url('./fonts/NotoKufiArabic-Regular.woff2') format('woff2'),
        url('./fonts/NotoKufiArabic-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Kufi Arabic';
    src: url('./fonts/NotoKufiArabic-SemiBold.woff2') format('woff2'),
        url('./fonts/NotoKufiArabic-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

